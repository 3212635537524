<template>
  <div class="home">
    <div class="banner">
      <div class="head">
        <div class="head-container">
          <div class="header_left">
            <img v-if="list.logo_url" class="header_left_img" :src="list.logo_url" alt="">
            <img v-else class="header_left_img" src="@/assets/logo.png" alt="">
          </div>
          <div class="tel">电话：4006 026 360</div>
        </div>
      </div>
      <div class="bg"></div>
      <img src="@/assets/banner.png" alt="">
    </div>
    <div class="content">
      <div class="section"><img src="@/assets/section1.png" alt=""></div>
      <div class="section"><img src="@/assets/section2.png" alt=""></div>
      <div class="section"><img src="@/assets/section3.png" alt=""></div>
      <div class="section"><img src="@/assets/section4.png" alt=""></div>
      <div class="section"><img src="@/assets/section5.png" alt=""></div>
      <div class="section"><img src="@/assets/section6.png" alt=""></div>
      <div class="section"><img src="@/assets/section7.png" alt=""></div>
      <div class="section"><img src="@/assets/section8.png" alt=""></div>
      <div class="section"><img src="@/assets/section9.png" alt=""></div>
      <div class="section"><img src="@/assets/section10.png" alt=""></div>
    </div>
    <div class="footer">
      <div class="footer-content">
        <div class="footer-left">
          <div class="tel-box">
            <div class="img-box"><img src="@/assets/tel.png" alt=""></div>
            <div class="text-box">
              <p>资讯热线</p>
              <p class="tel">400 6026 260</p>
            </div>
          </div>
          <div class="code-box clearfix">
            <div class="wxcode fl">
              <img src="@/assets/wechat_code_02.jpg" alt="">
              <p>添加企业微信</p>
            </div>
            <div class="qrcode fl">
              <img src="@/assets/wechat_code_01.png" alt="">
              <p>学习运营干货</p>
            </div>
          </div>
        </div>
        <div class="footer-right">
          <p class="company-name">{{ list.title }}</p>
          <p class="address">浙江省杭州市余杭区向往街欧美金融城EFC英国中心T2-40楼</p>
          <p>
            <template v-if="list.icp_info"><a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #858689" id="server_name">{{list.icp_info}}</a> |</template>
            <template v-if="list.public_icp_info"><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002014544" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><img style="margin-right: 5px;" src="../assets/备案图标.png" alt="">{{sub}}公网安备 {{list.public_icp_info}}号</a> |</template>
            客服电话：13819120013
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getList } from '@/api/record.js'
export default {
  name: 'Home',
  data () {
    return {
      list: {},
      host: 'duodiantong.com',
      sub: ''
      // icp_info: '浙公网安备 33011002014544号'
    }
  },
  created () {
    this.getDepressed()
  },
  methods: {
    async getDepressed () {
      // console.log(window.location.protocol)
      // console.log(window.location.host)
      // if (window.location.host !== 'localhost:8080') {
      this.host = window.location.host
      // }
      const { data: res } = await getList({
        domain: this.host
      })
      this.sub = res.result ? res.result.icp_info.slice(0, 1) : ''
      this.list = res.result || {}
      document.title = this.list.title
    }
  }
}
</script>

<style scoped>
  .banner {
    position: relative;
    height: 1100px;
    background: #f9faff;
  }
  .head {
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .head-container {
    width: 1200px;
    height: 68px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .head-container .header_left_img {
    height: 38px;
  }
  .head-container .tel {
    font-size: 20px;
    color: #fff;
  }
  .banner .bg {
    height: 500px;
    background: linear-gradient(to bottom, #4591ff, #004be1)
  }
  .banner>img {
    width: 1100px;
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
  .section {
    padding: 100px 0;
    text-align: center;
  }
  .section img {
    width: 1100px;
  }
  .footer {
    width: 100%;
    height: 446px;
    background: #071829;
  }
  .footer-content {
    width: 1200px;
    height: 446px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tel-box {
    display: flex;
    align-items: center;
  }
  .tel-box img {
    width: 60px;
  }
  .tel-box .text-box {
    margin-left: 20px;
    font-size: 16px;
    line-break: 20px;
    color: #fff;
    text-align: left;
  }
  .tel-box .text-box .tel {
    font-size: 28px;
    line-height: 22px;
    margin-top: 10px;
    font-weight: bold;
  }
  .code-box {
    margin-top: 36px;
  }
  .code-box img {
    width: 150px;
  }
  .code-box p {
    font-size: 14px;
    color: #ccc;
    margin-top: 10px;
    text-align: center;
  }
  .code-box .qrcode {
    margin-left: 38px;
  }
  .footer-right {
    margin-left: 100px;
    padding-top: 150px;
    color: #6b7c8c;
    font-size: 16px;
    line-height: 30px;
    text-align: left;
  }
  .footer-right .company-name {
    font-size: 18px;
    font-weight: 500;
  }
  .footer-right a {
    color: #6b7c8c;
    text-decoration: none;
  }
  .clearfix::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    line-height: 0;
    clear: both;
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
</style>