import axios from 'axios'
// import router from '@/router'

const service = axios.create({
  // baseURL: 'http://dev.api.qiming.qibcms.com'
  baseURL: 'http://prod.api.qibcms.com'
})
// 请求拦截器
service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    config.headers.authorization = token // 获取本地的token
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    return response
  }
)
export default service
